import * as types from '../../actions/actionTypes'

// **  Initial State
const initialState = {
  accessToken: '',
  identityToken: '',
  authorizationCode: '',
  userData: {},
  alerts: [],
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.data,
      }
    case types.SET_IDENTITY_TOKEN:
      return {
        ...state,
        identityToken: action.data,
      }
    case types.SET_AUTHORIZATION_CODE:
      return {
        ...state,
        authorizationCode: action.data,
      }
    case types.SET_USER_DATA:
      return {
        ...state,
        userData: action.data,
      }
    case types.RESET_USER_DATA:
      return {
        ...state,
        identityToken: '',
        authorizationCode: '',
        userData: {},
      }
    case types.SET_USER_PROFILE_IMAGE:
      return {
        ...state,
        userData: {
          ...state.userData,
          imageURL: action.data,
        },
      }
    case types.SET_ALERTS:
      return {
        ...state,
        alerts: action.data,
      }
    default:
      return state
  }
}

export default authReducer
