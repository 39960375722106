import { createContext, useState, useContext } from 'react'

const NotificationContext = createContext({})

const NotificationContextProvider = ({ children }) => {
  const [isNotificationOpen, setNotificationOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [type, setType] = useState('error')

  const close = () => setNotificationOpen(false)

  /**
   *
   * @param {string} _message - Custom message to display.
   * @param {string} _type - Type of notfication. default is "Error"
   */
  const notify = (_message, _type) => {
    setMessage(_message)
    setType(_type)
    setNotificationOpen(true)
  }

  return (
    <NotificationContext.Provider value={{ isNotificationOpen, close, notify, message, type }}>
      {children}
    </NotificationContext.Provider>
  )
}

const useNotifications = () => useContext(NotificationContext)

export { NotificationContext, NotificationContextProvider, useNotifications }
