export const GET_BUSINESSDATA = 'GET_BUSINESSDATA'
export const GET_WEEKDAYDATA = 'GET_WEEKDAYDATA'
export const GET_PROFESSIONDATA = 'GET_PROFESSIONDATA'
export const GET_INDUSTRYDATA = 'GET_INDUSTRYDATA'
export const GET_AREAOFEXPERTISEDATA = 'GET_AREAOFEXPERTISEDATA'
export const GET_EDUCATIONQULIFICATIONDATA = 'GET_EDUCATIONQULIFICATIONDATA'
export const GET_COUNTRYDATA = 'GET_COUNTRYDATA'
export const GET_DISTRICTDATA = 'GET_DISTRICTDATA'
export const GET_GENDERDATA = 'GET_GENDERDATA'
export const GET_TITLEDATA = 'GET_TITLEDATA'
export const GET_USERSTATUSDATA = 'GET_USERSTATUSDATA'
export const GET_AGEGROUPDATA = 'GET_AGEGROUPDATA'
export const GET_EXPERIENCEYEARRANGEDATA = 'GET_EXPERIENCEYEARRANGEDATA'
export const GET_TYPEOFBUSINESSDATA = 'GET_TYPEOFBUSINESSDATA'
export const GET_CONNECTIONTYPEDATA = 'GET_CONNECTIONTYPEDATA'
export const GET_COMMUNICATIONTYPEDATA = 'GET_COMMUNICATIONTYPEDATA'
export const GET_IDTYPEDATA = 'GET_IDTYPEDATA'
export const ADD_QULIFICATION = 'ADD_QULIFICATION'
export const ADD_PROFESSION = 'ADD_PROFESSION'
export const ADD_INDUSTRY = 'ADD_INDUSTRY'
export const ADD_AREAOFEXPERTISE = 'ADD_AREAOFEXPERTISE'
export const UPDATE_QULIFICATION = 'UPDATE_QULIFICATION'
export const UPDATE_PROFESSION = 'UPDATE_PROFESSION'
export const UPDATE_INDUSTRY = 'UPDATE_INDUSTRY'
export const UPDATE_AREAOFEXPERTISE = 'UPDATE_AREAOFEXPERTISE'
export const DELETE_QULIFICATION = 'DELETE_QULIFICATION'
export const DELETE_PROFESSION = 'DELETE_PROFESSION'
export const DELETE_INDUSTRY = 'DELETE_INDUSTRY'
export const DELETE_AREAOFEXPERTISE = 'DELETE_AREAOFEXPERTISE'
export const ADD_EMAIL = 'ADD_EMAIL'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const DELETE_EMAIL = 'DELETE_EMAIL'
export const ADD_CONTACT = 'ADD_CONTACT'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const ADD_PROFILE = 'ADD_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const SAVE_PROFILE = 'SAVE_PROFILE'
export const SET_LANGUAGEID = 'SET_LANGUAGEID'
export const GET_PROFILE = 'GET_PROFILE'
export const ADD_AVAILABILITY = 'ADD_AVAILABILITY'
export const ADD_REPEATAVAILABILITY = 'ADD_REPEATAVAILABILITY'
export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY'
export const DELETE_AVAILABILITY = 'DELETE_AVAILABILITY'
export const DELETE_REPEAT_AVAILABILITY = 'DELETE_REPEAT_AVAILABILITY'
export const UPDATE_REPEAT_AVAILABILITY = 'UPDATE_REPEAT_AVAILABILITY'
export const REMOVE_PROFESSION = 'REMOVE_PROFESSION'
export const REMOVE_INDUSTRY = 'REMOVE_INDUSTRY'
export const REMOVE_AREAOFEXPERTISE = 'REMOVE_AREAOFEXPERTISE'
export const GET_PROFILE_ID = 'GET_PROFILE_ID'
export const SHOW_MENTOR_PROFILE_AUTHORIZE_BUTTONS = 'SHOW_MENTOR_PROFILE_AUTHORIZE_BUTTONS'
export const SHOW_MENTEE_PROFILE_AUTHORIZE_BUTTONS = 'SHOW_MENTEE_PROFILE_AUTHORIZE_BUTTONS'
export const GET_MEETINGTYPEDATA = 'GET_MEETINGTYPEDATA'

// ** Authentication
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_IDENTITY_TOKEN = 'SET_IDENTITY_TOKEN'
export const SET_AUTHORIZATION_CODE = 'SET_AUTHORIZATION_CODE'
export const SET_USER_DATA = 'SET_USER_DATA'
export const RESET_USER_DATA = 'RESET_USER_DATA'
export const SET_USER_STATUS = 'SET_USER_STATUS'
export const SET_USER_PROFILE_IMAGE = 'SET_USER_PROFILE_IMAGE'
export const SET_ALERTS = 'SET_ALERTS'

// ** Loading Screen
export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN'
export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN'

// ** Status
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS'
export const SET_MEETING_STATUS = 'SET_MEETING_STATUS'

export const SET_REJECTREASON = 'SET_REJECTREASON'

export const GET_MENUDATA = 'GET_MENUDATA'
export const GET_CUSTOMERDISPATCHMODE = 'GET_CUSTOMERDISPATCHMODE'
