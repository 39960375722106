// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** User Language Data
import userMessagesEnglish from '@src/assets/data/locales/english.json'
import userMessagesSinhala from '@src/assets/data/locales/sinhala.json'
import userMessagesTamil from '@src/assets/data/locales/tamil.json'

// ** Menu msg obj
const menuMessages = {
  english: { ...userMessagesEnglish },
  sinhala: { ...userMessagesSinhala },
  tamil: { ...userMessagesTamil },
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState('english')
  const [messages, setMessages] = useState(menuMessages['english'])

  // ** Switches Language
  const switchLanguage = (lang) => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='english'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
