// ** Redux Imports
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import common from './common'
import language from './language'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'language', 'layout'],
}

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  common,
  language,
})

export default persistReducer(persistConfig, rootReducer)
