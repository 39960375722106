import * as types from '../../actions/actionTypes'

// **  Initial State
const initialState = {
  isShowLoadingScreen: false,
  azureConfiguration: {
    blobStorageAccount: 'artrackerstoragedev',
    blobStorageSASUrl:
      'https://artrackerstoragedev.blob.core.windows.net/?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2025-12-31T10:24:23Z&st=2022-02-01T02:24:23Z&spr=https&sig=W557jwzkov8PrBxFAIyaYKNgI8MpXKsN6MMvsrgyr8U%3D',
    blobStorageBaseUrl: 'https://artrackerstoragedev.blob.core.windows.net',
  },
  invoiceTypes: [
    { label: 'System Invoicing', value: 1 },
    { label: 'Manual Invoicing - SO amendment', value: 2 },
    { label: 'Manual Invoicing - SO creation', value: 3 },
    { label: 'Month End Invoicing', value: 4 },
  ],
  dispatchModes: [
    { label: 'Hardcopy', value: 3 },
    { label: 'Softcopy', value: 2 },
  ],
  soTableTabs: [
    { id: 1, text: 'All', icon: 'Home' },
    { id: 2, text: 'Assign Types', icon: 'Home' },
    { id: 3, text: 'Edit Type', icon: 'Home' },
    { id: 4, text: 'Send for Approval', icon: 'Home' },
    { id: 5, text: 'System Approval Pending', icon: 'Home' },
    { id: 6, text: 'Dispatch', icon: 'Home' },
    { id: 7, text: 'Acknowledgement', icon: 'Home' },
    { id: 8, text: 'To be Invoiced', icon: 'Home' },
    { id: 9, text: 'Resolve', icon: 'Home' },
    { id: 10, text: 'Review Pending', icon: 'Home' },
    { id: 11, text: 'Enter Invoice No', icon: 'Home' },
    { id: 12, text: 'Invoice Dispatch', icon: 'Home' },
  ],
  menuData: [],
  rejectReasons: [],
  //     { label: "Incomplete SO", value: 2 },
  //     { label: "Incomplete PO", value: 3 },
  //     { label: "Incomplete billing details", value: 4 },
  //     { label: "Wrong SO", value: 5 },
  //     { label: "Wrong PO", value: 6 },
  //     { label: "Wrong PO party (Vendor)", value: 7 },
  //     { label: "No approvals", value: 8 },
  //     { label: "Additional invoicing details not provided", value: 9 },
  //     { label: "Other", value: 1 }
  // ],
  customerDispatchModes: [
    { label: 'All', value: -1 },
    // { label: "N/A", value: 4 },
    { label: 'SCAN', value: 5 },
    { label: 'HARD COPY', value: 6 },
    { label: 'SCAN OR HARD COPY', value: 7 },
    { label: 'SCAN AND HARD COPY', value: 8 },
    { label: 'E-MAIL', value: 9 },
    { label: 'E-MAIL AND HARD COPY', value: 10 },
  ],
  salesOrderButtonsActions: [
    { label: 'Assign Type', value: 1 },
    { label: 'Edit', value: 2 },
    { label: 'Request System Approval', value: 3 },
    { label: 'Manual Approval', value: 4 },
    { label: 'Approve', value: 5 },
    { label: 'Reject', value: 6 },
    { label: 'Dispatch', value: 7 },
    { label: 'Re Dispatch', value: 8 },
    { label: 'Acknowledge', value: 9 },
    { label: 'Undelivered', value: 10 },
    { label: 'Resolve', value: 11 },
    { label: 'Resolution Reject', value: 12 },
    { label: 'Resolution Accept', value: 13 },
    { label: 'Update SO / PO', value: 14 },
    { label: 'To be invoiced Reject', value: 15 },
    { label: 'Hold', value: 16 },
    { label: 'Enter Invoice Number', value: 17 },
    { label: 'Invoice Dispatch', value: 18 },
    { label: 'View', value: 19 },
  ],
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING_SCREEN:
      return {
        ...state,
        isShowLoadingScreen: true,
      }
    case types.HIDE_LOADING_SCREEN:
      return {
        ...state,
        isShowLoadingScreen: false,
      }
    case types.SET_REJECTREASON:
      return {
        ...state,
        rejectReasons: action.data,
      }
    case types.GET_MENUDATA:
      return {
        ...state,
        menuData: action.data,
      }
    case types.GET_CUSTOMERDISPATCHMODE:
      return {
        ...state,
        customerDispatchModes: action.data,
      }
    default:
      return state
  }
}

export default commonReducer
