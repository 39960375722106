import * as types from '../../actions/actionTypes'
// import { FormattedMessage } from 'react-intl'

// **  Initial State
const initialState = {
  languageId: 1,
}

const LangageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LANGUAGEID:
      return {
        ...state,
        languageId: action.data,
      }
    default:
      return state
  }
}

export default LangageReducer
