import mock from '../mock'
// import { paginateArray } from '../utils'

const data =[
{
    "id": 1,
    "soNumber": "302323",
    "materialCode": "62U0U5G31MAA290BNLDL",
    "materialDescription": "61C025|N1|6-6.5|CID|290|B|NOD|POL",
    "orderQty": 67200,
    "uomPrefix": "NASDAQ",
    "noOfCartons": 77,
    "noOfPrintedCartons": 7,
    "noOfScannedCartons": 10,
  },
  {
    "id": 2,
    "soNumber": "301866",
    "materialCode": "81C028O20NGA330SNSTF",
    "materialDescription": "61C025|N1|7-7.5|CID|290|B|NOD|POL",
    "orderQty": 1576,
    "ouom": "NASDAQ",
    "noOfCartons": 98,
    "noOfPrintedCartons": 21,
    "noOfScannedCartons": 10,
  },
  {
    "id": 3,
    "soNumber": "289090",
    "materialCode": "62U0L2Y01KAA300BNFIN",
    "materialDescription": "61C025|N1|6-6.5|CID|290|B|NOD|POL",
    "orderQty": 1761,
    "ouom": "NYSE",
    "noOfCartons": 21,
    "noOfPrintedCartons": 7,
    "noOfScannedCartons": 10,
  },
  {
    "id": 4,
    "soNumber": "302241",
    "materialCode": "62C040B02MCA300BNNNT",
    "materialDescription": "61C025|N1|8-8.5|CID|290|B|NOD|POL",
    "orderQty": 1131,
    "ouom": "NASDAQ",
    "noOfCartons": 86,
    "noOfPrintedCartons": 29,
    "noOfScannedCartons": 10,
  },
  {
    "id": 5,
    "soNumber": "320801",
    "materialCode": "61C025N01ICA290BNPOL",
    "materialDescription": "61C025|N1|8-8.5|CID|290|B|NOD|POL",
    "orderQty": 1690,
    "ouom": "NYSE",
    "noOfCartons": 19,
    "noOfPrintedCartons": 41,
    "noOfScannedCartons": 10,
  },
  
]

mock.onGet('/api/datatables/mock-data').reply(config => {
  return [200, data]
})

// mock.onGet('/api/datatables/data').reply(config => {
//   // eslint-disable-next-line object-curly-newline
//   const { q = '', perPage = 10, page = 1 } = config
//   /* eslint-enable */

//   const queryLowered = q.toLowerCase()
//   const filteredData = data.filter(
//     item =>
//       /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
//       item.full_name.toLowerCase().includes(queryLowered) ||
//       item.post.toLowerCase().includes(queryLowered) ||
//       item.email.toLowerCase().includes(queryLowered) ||
//       item.age.toLowerCase().includes(queryLowered) ||
//       item.salary.toLowerCase().includes(queryLowered) ||
//       item.start_date.toLowerCase().includes(queryLowered)
//   )
//   /* eslint-enable  */

//   return [
//     200,
//     {
//       allData: data,
//       invoices: paginateArray(filteredData, perPage, page),
//       total: filteredData.length
//     }
//   ]
// })
